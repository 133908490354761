<template>
  <div class="panel-left">
    <div  v-for="(item, i) in menuConfig">
      <a v-if="item.isopen" :href="item.router" target="_blank">
        <div v-show="item.router" @click="getMenu(item.id)" :style="$route.name===item.name || $route.name==='templateCenterDetails' && i===1 ?characters:''" class="box">
          <i :class="item.icon" :style="{fontSize:item.fontsize+'px'}"></i>
          <span>{{item.label}}</span>
          <i v-if="item.id==='7'" :style="{fontSize:item.fontsize+'px'}" style="color: red;margin-left: 3px" class="iconfont icon-new1"></i>
        </div>
        <div class="menuTitle" v-show="!item.router">{{item.label}}</div>
      </a>
      <NuxtLink v-else class="link-no-underline" :to="item.router">
      <div v-show="item.router" @click="getMenu(item.id)" :style="$route.name===item.name || $route.name==='templateCenterDetails' && i===1 ?characters:''" class="box">
          <i :class="item.icon" :style="{fontSize:item.fontsize+'px'}"></i>
          <span>{{item.label}}</span>
        <i v-if="item.id==='7'" :style="{fontSize:item.fontsize+'px'}" style="color: red;margin-left: 3px" class="iconfont icon-new1"></i>
      </div>
        <div class="menuTitle" v-show="!item.router">{{item.label}}</div>
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, reactive } from 'vue';
interface IMenuConfig {
  id: string;
  label: string;
  icon?: string;
  children?: IMenuConfig[];
}
const menuSelect = ref('0')
const characters = reactive({
  backgroundColor: "#F5F7FD",
  fontWeight: "700",
});
const getMenu = ((row)=>{
  menuSelect.value = row
})
const menuConfig: UnwrapNestedRefs<({ router: string; icon: string; name: string; label: string; id: string } | { router: string; icon: string; name: string; label: string; id: string } | { router: string; icon: string; name: string; label: string; id: string } | { label: string } | { router: string; icon: string; name: string; label: string; id: string } | { router: string; icon: string; label: string; id: string } | { label: string } | { router: string; icon: string; label: string; id: string } | { router: string; icon: string; label: string; id: string } | { router: string; icon: string; label: string; id: string })[]> = reactive([
  {
    label: '为你推荐',
    id: '1',
    icon: 'iconfont icon-a-xingxingshoucang',
    router:'/',
    name:'/',
    isopen:false,
    fontsize:16,
  },
  {
    label: '模板中心',
    id: '2',
    icon: 'iconfont icon-mobanzhongxin',
    router: '/templateCenter',
    name:'templateCenter',
    isopen:false,
    fontsize:16,
  },
  {
    label: '素材中心',
    id: '3',
    icon: 'iconfont icon-sucai',
    router: '/materialCenter',
    name:'materialCenter',
    isopen:false,
    fontsize:15,
  },
  {
    label: '个人空间',
  },
  {
    label: '我的设计',
    id: '4',
    icon: 'iconfont icon-sucaizhongxin',
    router: '/myDesign',
    name:'myDesign',
    isopen:false,
    fontsize:16,

  },
  {
    label: '创建设计',
    id: '5',
    icon: 'iconfont icon-a-xingzhuang1858kaobei',
    router: '/createDesign',
    name:'createDesign',
    isopen:false,
    fontsize:14,
  },
  {
    label: '工具箱',
  },
  {
    label: '图文排版',
    id: '6',
    icon: 'iconfont icon-tuwen',
    router: 'https://www.jianpanmiao.com',
    isopen:true,
    fontsize:18,
  },
  {
    label: '精美素材',
    id: '7',
    icon: 'iconfont icon-tupian3',
    router: 'https://www.houtuwang.com',
    isopen:true,
    fontsize:16,
  },
  {
    label: '一键成文',
    id: '8',
    icon: 'iconfont icon-zhinengAI',
    router: 'https://www.jizhiya.com',
    isopen:true,
    fontsize:16,
  },
  // {
  //   label: 'AI',
  //   id: '4',
  //   icon: 'ios-mail-open',
  // },
  // {
  //   label: 'ppt',
  //   id: '5',
  //   icon: 'md-lock',
  // },
  // {
  //   label: '免扣元素',
  //   id: '6',
  //   icon: 'md-create',
  // },
  // {
  //   label: '精品图库',
  //   id: '7',
  //   icon: 'ios-copy',
  // },
  // {
  //   label: '更多',
  //   id: '8',
  //   icon: 'md-apps',
  //   children: [
  //     {
  //       label: '二维码',
  //       id: '8-1',
  //     },
  //     {
  //       label: '热点',
  //       id: '8-2',
  //     },
  //     {
  //       label: '百宝箱',
  //       id: '8-3',
  //     },
  //   ],
  // },
]);
// const selectMenis = function (name?: string | number) {
//   console.log(name);
const selectMenis  = ((row)=>{
  console.log(row,'111')
});
</script>

<style lang='less' scoped>
.link-no-underline  {
  text-decoration: none;
}
.panel-left {
  width: 200px;
  height: 100%;
  background-color: #fff;
  overflow-y: auto;
  padding-left: 20px;
  padding-top: 30px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  .box:hover{
    background-color: #F5F7FD;
  }
  .box{
    padding-left: 10px;
    width: 100%;
    height: 40px;
    font-size: 14px;
    color: #1b2337;
    margin-bottom: 3px;
    border-radius: 6px;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: left;
    span{
      margin-left: 10px;
    }
    .text{
      font-weight: 700;
    }
  }
   .menuTitle{
    font-size: 12px;
    color: #8693ab;
     padding: 8px 0;
  }
}
//  ::v-deep {
//    .ivu-menu {
//      width: 200px !important;
//    }
//  }
//}
//.ivu-menu-light.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu):after{
//  background: #81D8D0 !important;
//}
//.ivu-menu-vertical.ivu-menu-light:after{
//  background: unset !important;
//}
//.ivu-icon{
//  font-size: 18px;
//}
//.ivu-menu-vertical .ivu-menu-item:hover, .ivu-menu-vertical .ivu-menu-submenu-title:hover{
//  color: #81D8D0;
//}
//.ivu-menu-light.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu){
//  color: #81D8D0;
//}
</style>
